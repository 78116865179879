import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import "./style.css";
import { Grid } from "@material-ui/core";
import { convertDateToDDMMYY, getDateDifferenceInDays, getMidDate, numberWithSpaces } from "utils/format";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import HorizontalTimeline from "Components/HotizontalTImeline/HorizontalTimeline";
import { isDateBetween } from "utils";

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    minWidth: 51,
    fontSize: "14px",
    lineHeight: "14px",
    padding: "6px 2px",
    textAlign: "left",
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: "22px",
    fontFamily: "Roboto",
    fontStyle: "italic",
    "&:hover": {
      color: "var(--primary)",
      opacity: 1,
    },
    "&$selected": {
      color: "var(--primary)",
      fontWeight: theme.typography.fontWeightBold,
    },
    "&:focus": {
      color: "var(--primary)",
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      style={{ paddingLeft: "10px", width: "100%" }}
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      
    >
      {value === index && <Box p={0} m={0} pl={1} ml={1}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    marginTop: "24px",
    "& a": {
      color: "var(--primary)",
      textDecoration: "none",
    },
    "& pre": {
      fontFamily:
        "Lucida Sans Typewriter, DejaVu Sans Mono, Bitstream Vera Sans Mono, Liberation Mono, Nimbus Mono L , Monaco, Courier New ,Courier, monospace",
      whiteSpace: "pre-wrap",
      overflowX: "auto",
      tabWidth: 4,
      background: "rgba(0,0,0, 0.6)",
      color: "white",
      padding: 20,
      borderRadius: 5,
    },
    "& ul": {
      margin: 0,
      padding: 0,
    },
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    minWidth: "90px",
  },
  flexContainerVertical: {
    alignItems: "flex-start",
  },
}));


const OverView = (props) => {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [timelineValue, setTimelineValue] = useState(0);
  const [previous, setPrevious] = useState(0);
  /*
Format: YYYY-MM-DD
Note: Make sure dates are sorted in increasing order
*/
  const [timelineData, setTimeLineData] = useState([])




  useEffect(() => {
   const datesList = [
      {title: "Announced" , value: props.announced_date},
      {title: "Start", value: props.start_date},
      {title: "Team merger deadline", value: props?.team_merger_date},
      {title: "Final submission", value: props?.final_submission_date},
      {title: "Final Results", value: props?.end_date,}
   ]
    
    const currentDate = (new Date()).toISOString()
    
    if (isDateBetween(props.start_date, props?.end_date, (new Date()).toISOString())) {
        datesList.push({ title: "Current", value: currentDate })
    }
    
    setTimeLineData(datesList.sort((a, b) => new Date(a.value) - new Date(b.value)))
  }, [])


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const prizesList =
    props.prizes &&
    props.prizes.map((prize) => {
      let place = "1st place";
      switch (prize.place) {
        case 1:
          place = "1st place";
          break;
        case 2:
          place = "2nd place";
          break;
        case 3:
          place = "3rd place";
          break;
        default:
          place = prize.place + "th place";
          break;
      }
      return (
        <Grid container>
          <Grid xs={1} className="prize-title" item>
            {place}
          </Grid>
          <Grid xs={2} className="prize-value" item>
            € {numberWithSpaces(prize.prize_amount)}
          </Grid>
        </Grid>
      );
    });
  
  
  
  
  
  console.log("TimeDELTE", timelineData)

  
  return (
    <>
      {/* time-line box */}

      <Box style={{
        marginTop: "12px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: '100%',
        height: '95px',
      }}>

        <HorizontalTimeline
          index={timelineValue}
          indexClick={(index) => {
            setPrevious(value)
            setTimelineValue(index)
          }}
          styles={{ background: '#ffffff', foreground: '#5dd39e', outline: '#5dd39e' }}
          // linePadding={40}
          isOpenBeginning={false}
          isOpenEnding={false}
          showArrowButtons={false}
          minEventPadding={10}
          maxEventPadding={300}
          daysToGo={props?.final_submission_date}
          count={timelineData.length}
          getLabel={(data) => {
            console.log("DATE-DATA", data)
            return convertDateToDDMMYY(data.value)
          }}
          values={timelineData } />
      </Box>
      
      <div className={`${classes.root} competition-Overview`}>
        <Tabs
          indicatorColor="primary"
          orientation="vertical"
          variant="standard"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          className={classes.tabs}
          classes={{ flexContainerVertical: classes.flexContainerVertical }}
        >
          <AntTab label="Description" {...a11yProps(0)} />
          <AntTab label="Evaluation" {...a11yProps(1)} />
          {/* <AntTab label="Timeline" {...a11yProps(2)} /> */}
          <AntTab label="Prizes &nbsp; &nbsp;&nbsp;" {...a11yProps(2)} />
        </Tabs>
        <TabPanel value={value} index={0}>
          <div
            className="details"
            dangerouslySetInnerHTML={{ __html: props.description }}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <div
            className="details"
            dangerouslySetInnerHTML={{ __html: props.evaluation }}
          />
        </TabPanel>
        {/* <TabPanel value={value} index={2}>
          <div
            class="details"
            dangerouslySetInnerHTML={{ __html: props.timeline }}
          />
        </TabPanel> */}
        <TabPanel value={value} index={2}>
          <div className="details">
            <div className="leader-board-title ">Prizes</div>
            <div className="prize-list">{prizesList}</div>
            <div dangerouslySetInnerHTML={{ __html: props.prize_details }} />
          </div>
        </TabPanel>
      </div>
    </>
  );
};

export default OverView;
